<template>
  <dashboard-layout>
    <div class="w-10/12 mx-auto">
      <router-link
        :to="{ name: 'list-syndicates' }"
        class="bg-green-1000 text-white px-6 py-3 rounded rounded-md border border-gray-400 focus:outline-none"
      >
        <svg
          style="display: initial;"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
        voltar
      </router-link>
      <div class="p-4 flex">
        <h1 class="text-3xl">
          Novo Sindicato
        </h1>
      </div>
      <form-wizard
        @on-complete="onComplete"
        :start-index="0"
        color="#69CA5E"
        title=""
        subtitle=""
        ref="form"
        next-button-text="Próximo"
        back-button-text="Anterior"
        finish-button-text="Finalizar"
      >
        <tab-content title="Identificação" icon="ti-user">
          <div
            class="w-12/12 border border-green-600  rounded-lg py-3 px-5 mx-auto mt-5"
          >
            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="syndicate">Sindicato</label>
                <input
                  v-model="$v.syndicate.name.$model"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="syndicate"
                  type="text"
                  placeholder="Nome do sindicato"
                  autocomplete="off"
                />
                <div v-if="$v.syndicate.name.$error">
                  <div class="error" v-if="!$v.syndicate.name.required">
                    Campo obrigatório.
                  </div>
                  <div class="error" v-else-if="!$v.syndicate.name.maxLength">
                    O nome do sindicato deve ter no máximo
                    {{ $v.syndicate.name.$params.maxLength.max }}
                    caracteres.
                  </div>
                </div>
              </div>

              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="document">CNPJ</label>
                <input
                  v-model="$v.syndicate.document.$model"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="document"
                  type="text"
                  placeholder="CNPJ"
                  v-mask="'##.###.###/####-##'"
                  autocomplete="off"
                />
                <div v-if="$v.syndicate.document.$error">
                  <div class="error" v-if="!$v.syndicate.document.required">
                    Campo obrigatório.
                  </div>
                </div>
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="telephone">Telefone fixo</label>
                <input
                  v-model="syndicate.telephone"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="telephone"
                  type="text"
                  placeholder="Telefone"
                  v-mask="'(##) ####-####'"
                  autocomplete="off"
                />
              </div>

              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="status">Status</label>
                <vue-select
                  id="status"
                  placeholder="Selecione uma opção"
                  :options="syndicate.status"
                  v-model="$v.syndicate.selected_status.$model"
                />
                <div v-if="$v.syndicate.selected_status.$error">
                  <div
                    class="error"
                    v-if="!$v.syndicate.selected_status.required"
                  >
                    Campo obrigatório.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab-content>
        <tab-content title="Endereço" icon="ti-location-pin">
          <div
            class="w-12/12 border border-green-600  rounded-lg py-3 px-5 mx-auto mt-5"
          >
            <div class="flex w-full mb-5">
              <div class="flex w-3/12 flex-col mr-5">
                <label class="mb-2" for="cep">CEP</label>
                <input
                  id="cep"
                  type="text"
                  v-model="address.cep"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="CEP"
                  v-mask="'#####-###'"
                />
              </div>

              <div class="flex w-7/12 flex-col mr-5">
                <label class="mb-2" for="address">Endereço</label>
                <input
                  id="address"
                  type="text"
                  v-model="address.address"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Endereço"
                />
              </div>

              <div class="flex w-2/12 flex-col mr-5">
                <label class="mb-2" for="number">Número</label>
                <input
                  id="number"
                  type="text"
                  v-model="address.number"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Número"
                />
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="complement">Complemento</label>
                <input
                  id="complement"
                  type="text"
                  v-model="address.complement"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Complemento"
                />
              </div>

              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="neighborhood">Bairro</label>
                <input
                  id="neighborhood"
                  type="text"
                  v-model="address.neighborhood"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Bairro"
                />
              </div>
            </div>
            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="state">Estado</label>
                <multiselect
                  id="state"
                  v-model="address.selected_state"
                  deselect-label="Clique para remover"
                  track-by="name"
                  label="name"
                  select-label="Clique para selecionar"
                  selected-label="Selecionado"
                  placeholder="Selecione um estado"
                  :options="address.states"
                  @select="fetchCities"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.name }}
                  </template>
                  <span slot="noOptions">Nenhum estado encontrado.</span>
                </multiselect>
              </div>

              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="city">Cidade</label>
                <multiselect
                  id="city"
                  v-model="$v.address.selected_city.$model"
                  deselect-label="Clique para remover"
                  track-by="name"
                  label="name"
                  select-label="Clique para selecionar"
                  selected-label="Selecionado"
                  placeholder="Selecione uma cidade"
                  :options="address.cities"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.name }}
                  </template>
                  <span slot="noOptions">Nenhuma cidade encontrada.</span>
                </multiselect>
                <div v-if="$v.address.selected_city.$error">
                  <div class="error" v-if="!$v.address.selected_city.required">
                    Campo obrigatório.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout';
import { mask } from 'vue-the-mask';
import { required, maxLength } from 'vuelidate/lib/validators';
import axios from '@/utils/axios';

export default {
  name: 'CreateSyndicate',

  title() {
    return `${process.env.VUE_APP_NAME} | Criar Sindicato`;
  },

  components: {
    DashboardLayout
  },

  directives: { mask },

  data() {
    return {
      syndicate: {
        name: '',
        document: '',
        telephone: '',
        status: [
          { label: 'Ativo', value: 1 },
          { label: 'Inativo', value: 0 }
        ],
        selected_status: null
      },
      address: {
        cep: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        country: '',
        states: [],
        selected_state: null,
        cities: [],
        selected_city: null
      }
    };
  },

  validations: {
    syndicate: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      document: { required },
      selected_status: {
        required
      }
    },
    address: {
      selected_city: { required }
    }
  },

  methods: {
    onComplete() {
      if (this.$v.syndicate.$invalid) {
        this.$refs.form.changeTab(1, 0);

        this.$v.syndicate.name.$touch();
        this.$v.syndicate.document.$touch();
        this.$v.syndicate.selected_status.$touch();
      } else if (this.$v.address.$invalid) {
        this.$v.address.selected_city.$touch();
      } else {
        const syndicateAttributes = {
          name: this.syndicate.name,
          document: this.syndicate.document,
          telephone: this.syndicate.telephone,
          status: this.syndicate.selected_status.value
        };

        const addressAttributes = {
          city_id: this.address.selected_city.id,
          cep: this.address.cep,
          address: this.address.address,
          number: this.address.number,
          complement: this.address.complement,
          neighborhood: this.address.neighborhood
        };

        axios
          .post('/api/sindicatos', syndicateAttributes)
          .then(({ data }) => {
            this.$toast.success(data.data.message);

            axios
              .post(
                `/api/sindicatos/${data.data.data.id}/endereco`,
                addressAttributes
              )
              .then(({ data }) => {
                this.$toast.success(data.data.message);

                this.$router.push({ name: 'list-syndicates' });
              })
              .catch(({ response }) => {
                Object.values(response.data.errors).forEach(error => {
                  this.$toast.error(...error);
                });
              });
          })
          .catch(({ response }) => {
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
            });
          });
      }
    },

    async fetchStates() {
      await axios.get('/api/estados').then(({ data }) => {
        data.data.forEach(state =>
          this.address.states.push({
            id: state.id,
            name: state.name
          })
        );
      });
    },

    async fetchCities(state) {
      this.address.cities = [];
      this.address.selected_city = null;

      await axios.get(`/api/estados/${state.id}/cidades`).then(({ data }) => {
        data.data.forEach(city =>
          this.address.cities.push({
            id: city.id,
            name: city.name
          })
        );
      });
    }
  },
  created() {
    this.fetchStates();
  }
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
